.container{
    display: flex;
}


.link{
    text-decoration: none;
    color: inherit;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 10px!important;
    justify-content: center!important;
}

.MuiDataGrid-cell   {
    text-align: center!important;
    justify-content: center!important;
    padding: 0 15px!important;
}

.css-13cymwt-control { 
    border-color: black!important;
}

.globalContainer {
}

.loginBackground {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      url("https://ik.imagekit.io/dnddecpho/Haofa/200523-%E5%93%81%E7%89%8C_%E8%B1%AA%E6%B3%95_%E5%A7%9A%E6%80%BB_1921-%E9%AB%98%E7%AB%AF%E7%B2%BE%E4%BF%AE_2%E4%B8%AA_%E5%9C%BA%E6%99%AF03-02_mvVaTOjVr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1665388166000")
        center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productListButton {
    border: none;
    border-radius: 10px;
    padding: 15px 0px;
    background-color: #014451;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.h-30vh {
    height: 30vh;
}

.w-70vw {
    width: 70vw;
}

.galleryItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.deleteButton {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin: 2rem;
}

.deleteButton:hover {
    background-color: darkred;
}

.MuiDataGrid-root {
    justify-content: center!important;
}